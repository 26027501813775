exports.columns = [
  {data: 'id'},
  {data: 'name'},
  {data: 'type'},
  {data: 'price'},
  {data: 'free'},
  {data: 'from'},
  {data: 'until'},
  {data: 'enabled'},
  {data: 'action', sortable: false, searchable: false, className: 'uk-text-right uk-table-shrink uk-text-nowrap'}
]
exports.config = {}
